import styled from '@emotion/styled';

const PageWrapper = styled.div``;

const NotFound = () => {
  return (
    <PageWrapper>
      <h1>404 Not Found</h1>
    </PageWrapper>
  );
};

export default NotFound;
