import styled from '@emotion/styled';

const Wrapper = styled.section`
  display: grid;
  place-content: center;
  height: 100vh;
  max-height: 100dvh;
  width: 100vw;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
`;

const Title = styled.h1`
  ${({ theme }) => theme.fonts.primary};
  font-size: 2.65rem;
`;
const Caption = styled.p`
  ${({ theme }) => theme.fonts.captionRegular};
  font-size: ${({theme}) => theme.fontSizes.caption}
`;

const Home = () => {
  return (
    <Wrapper>
      <Title>TYRON SHI</Title>
      <Caption>Site V2 In Progress</Caption>
    </Wrapper>
  );
};
export default Home;
