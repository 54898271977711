import colors from './colors';
import fonts from './fonts';
import fontSizes from './fontSizes';
import indices from './indices';
import layouts from './layouts';
import mediaQueries from './mediaQueries';
import mixins from './mixins';

const theme = {
  colors,
  fonts,
  fontSizes,
  indices,
  layouts,
  mediaQueries,
  mixins,
};

export default theme;
